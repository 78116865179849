<template>
  <div class="mainform">
    <div class="form">
      <el-form  class="content bascform" ref="addpayplan" :model="form"  label-width="100px">
        <div class="col">
          <el-form-item label="供应商" prop="supplier" >
            <el-select size="small" filterable
            :remote-method="requestSupplierOption"
            :loading="supplierLoading"
            @change="supChange"
            v-model="form.supplier">
              <el-option v-for="(item) in supplierOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { requestForm } from '@/assets/js/http.js'

export default {
  name: 'AddSupDialog',
  components: { ...Component },
  data: function () {
    return {
      form: {},
      supplierOptions: [],
      supplierLoading: false,
      dialogShow: false,
      bankShow: false,
      companyCode: ''
    }
  },
  created () {
    this.requestSupplierOption()
  },
  methods: {
    // 获取供应商
    requestSupplierOption (val) {
      this.supplierLoading = true
      this.supplierOptions = []
      requestForm('/api/supplier/basicData/getForSelect', 'post', { supplier: val }).then((Response) => {
        if (Response.code === '200') {
          for (const item of Response.data) {
            this.supplierOptions.push({
              key: `${item.name}`,
              label: `${~~item.erpCode}-${item.name}`,
              value: `${item.name}`,
              erpCode: `${~~item.erpCode}`
            })
          }
        }
        this.supplierLoading = false
      })
    },
    supChange (data) {
      for (const item of this.supplierOptions) {
        if (item.value === data) {
          this.form.supplierCode = item.erpCode
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
@import '@/assets/css/elForm.scss';
.mainform{
  .form{
    padding: 0px 0px;
    .el-form{
      .col{
        width: 85%;
      }
    }
  }
}
</style>
